import isEmpty from 'lodash/isEmpty';
import jQuery from 'jquery';

const VcomLoginMessage = function VcomLoginMessage() {
  function showMessage(permission, messageText, messageTitle, fbHeadline, fbText) {
    if (permission === 0) {
      if (!isEmpty(messageText) && !isEmpty(messageTitle)) {
        const errorModal = jQuery('#error-modal');
        errorModal.find('.modal-title').html(messageTitle);
        errorModal.find('.modal-body').html(`<p>${messageText}</p>`);
        errorModal.modal();
      } else {
        const alertHeading = `<p class="alert-heading font-weight-bold">${fbHeadline}</p>`;
        const alert = `<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">${alertHeading}<p class="mb-0">${fbText}</p></div>`;
        jQuery('#error-alert-container').html(alert);
      }
    }
  }

  return {
    showMessage,
  };
};

window.VcomLoginMessage = new VcomLoginMessage();
